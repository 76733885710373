import * as S from './styles';
import { useState, useEffect } from 'react';
import { useTarefa } from '../../hooks/tarefa';
import { useOrigem } from '../../hooks/origem';
import { useCheckList } from '../../hooks/checklist';
import {
  TextField,
  FormControl,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import ReactSelect from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import api from '../../services/api';

import { toast } from 'react-toastify';

import moment from 'moment';

import { useParams } from 'react-router-dom';

import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useEmpresa } from '../../hooks/empresa';

moment.locale('pt-br');

export function FormCadastraTarefa() {
  const { createTarefa } = useTarefa();
  const { origemListaAtiva } = useOrigem();
  const { checklistListaAtiva } = useCheckList();
  const { empresaListaAtiva } = useEmpresa();
  const { id } = useParams();

  const [usuarioLista, setUsuarioLista] = useState('');

  const [uuidGerado, setUUIDGerado] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [conclusao_prevista, setConclusaoPrevista] = useState(null);
  const [inicio_previsto, setInicioPrevisto] = useState(null);
  const [problema, setProblema] = useState('');
  const [acao, setAcao] = useState('');
  const [origem, setOrigem] = useState('');
  const [checklist, setCheckList] = useState('');
  const [empresa, setEmpresa] = useState('');

  const [destinatario, setDestinatario] = useState('');
  const [assunto, setAssunto] = useState('');
  const [corpo_email, setCorpoEmail] = useState('');

  const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));

  // LISTA USUÁRIO
  useEffect(() => {
    api
      .get('/usuario')
      .then((response) => {
        setUsuarioLista(response.data.itens);
      })
      .catch((err) => {});

    setUUIDGerado(uuidv4());
  }, []);

  //CRIAR AUDITORIA

  const handleCriarAuditoria = async (event) => {
    event.preventDefault();

    if (acao === '') {
      toast.error('Digite um nome', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    } else if (responsavel === '') {
      toast.error('Defina um responsável', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    } else if (checklist === '') {
      toast.error('Vincule um questionário a auditoria', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    } else if (inicio_previsto === '' || inicio_previsto === 'Data inválida') {
      toast.error('Defina um data de início previsto válida', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    } else if (
      conclusao_prevista === '' ||
      conclusao_prevista === 'Data inválida'
    ) {
      toast.error('Defina um data de conclusão prevista válida', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    } else if (origem === '') {
      toast.error('Defina uma origem', {
        position: toast.POSITION.TOP_CENTER
      });

      return false;
    }

    let inicio = moment(inicio_previsto).format('DD/MM/YYYY HH:mm');
    let conclusao = moment(conclusao_prevista).format('DD/MM/YYYY HH:mm');

    let data = {
      uuid: uuidGerado,
      cliente: id,
      responsavel,
      solicitante: String(localStorage.getItem('LPROWORK')),
      inicio_previsto: inicio,
      conclusao_prevista: conclusao,
      acao,
      problema,
      empresa,
      origem,
      status: 1
    };

    let dataEmail = {
      vinculo: uuidGerado,
      destinatario,
      assunto,
      corpo: corpo_email,
      status: 1,
      emailExecuta: email_automatico
    };

    let dataChecklist = {
      tarefa: uuidGerado,
      checklist
    };

    let result = await createTarefa(data, dataEmail, dataChecklist);

    if (result.erro === false) {
      setUsuarioLista('');
      setUUIDGerado('');
      setResponsavel('');
      setConclusaoPrevista('');
      setProblema('');
      setAcao('');
      setOrigem('');
      setCheckList('');
      setDestinatario('');
      setAssunto('');
      setCorpoEmail('');
    }
  };

  //EMAIL AUTOMÁTICO
  const [email_automatico, setCheckedEmail] = useState(false);

  const handleEmailAutomatico = (event) => {
    setCheckedEmail(event.target.checked);
    if (email_automatico === true) {
      setDestinatario('');
      setAssunto('');
      setCorpoEmail('');
    }
  };

  const onInicioPrevisto = (date) => {
    setInicioPrevisto(date);
    /* setInicioPrevisto(moment(date).format('DD/MM/YYYY HH:mm')); */
  };

  const onConclusaoPrevista = (date) => {
    setConclusaoPrevista(date);
  };

  const selectOrigem = () => {
    let options = [];
    origemListaAtiva?.itens.map((item) => {
      options.push({ value: item.id, label: item.titulo });
    });

    console.log(options);

    return options;
  };

  return (
    <>
      <S.Container onSubmit={handleCriarAuditoria}>
        <S.ContainerCardForm>
          <div className="card-form">
            <Card className="item">
              <CardContent>
                <S.TituloTopo>Dados</S.TituloTopo>
                <S.Column>
                  <TextField
                    variant="outlined"
                    label={'Nome'}
                    value={acao}
                    placeholder="Nome"
                    onChange={(event) => setAcao(event.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    label={'Descrição'}
                    value={problema}
                    placeholder="Descrição"
                    onChange={(event) => setProblema(event.target.value)}
                  />
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-required-label">
                      Responsável
                    </InputLabel>
                    <Select
                      variant="outlined"
                      onChange={(event) => setResponsavel(event.target.value)}
                      value={responsavel}
                      label={'Responsável'}
                      placeholder="Responsável"
                    >
                      <MenuItem value="">
                        <em>Selecione um responsável</em>
                      </MenuItem>
                      {usuarioLista
                        ? usuarioLista.map((lista) => (
                            <MenuItem
                              key={`status${lista.uuid}`}
                              value={lista.uuid}
                            >
                              {lista.nome}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                  {usuarioPermissao === 1 ? (
                    <FormControl className="form_control" variant="outlined">
                      <InputLabel id="demo-simple-select-required-label">
                        Empresa
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={(event) => setEmpresa(event.target.value)}
                        value={empresa}
                        label={'Empresa'}
                        placeholder="Empresa"
                      >
                        <MenuItem value="">
                          <em>Selecione um empresa</em>
                        </MenuItem>
                        {empresaListaAtiva.length > 0
                          ? empresaListaAtiva.map((lista) => (
                              <MenuItem key={lista.uuid} value={lista.id}>
                                {lista.nome}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  ) : null}
                </S.Column>
              </CardContent>
            </Card>
            <Card className="item">
              <CardContent>
                <S.TituloTopo>Vinculos</S.TituloTopo>
                <S.Column>
                  <FormControl className="form_control" variant="outlined">
                    <InputLabel id="demo-simple-select-required-label">
                      Questionário
                    </InputLabel>
                    <Select
                      variant="outlined"
                      onChange={(event) => setCheckList(event.target.value)}
                      value={checklist}
                      label={'Questionário'}
                      placeholder="Questionário"
                    >
                      <MenuItem value="">
                        <em>Selecione um questionário</em>
                      </MenuItem>

                      {checklistListaAtiva &&
                        checklistListaAtiva.map((lista) => (
                          <MenuItem key={lista.uuid} value={lista.uuid}>
                            {lista.titulo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <ReactSelect
                      menuPosition="fixed"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          background: '#fff',
                          borderColor: '#9e9e9e',
                          minHeight: 58,
                          height: 58,
                          boxShadow: state.isFocused ? null : null
                        }),

                        valueContainer: (provided, state) => ({
                          ...provided,
                          height: 58,
                          padding: '0 6px'
                        }),

                        input: (provided, state) => ({
                          ...provided,
                          margin: '0px'
                        }),
                        indicatorSeparator: (state) => ({
                          display: 'none'
                        }),
                        indicatorsContainer: (provided, state) => ({
                          ...provided,
                          height: 58
                        })
                      }}
                      placeholder="Origem"
                      onChange={({ value }) => setOrigem(value)}
                      options={origemListaAtiva?.itens && selectOrigem()}
                    />
                  </FormControl>
                </S.Column>
              </CardContent>
            </Card>
            <Card className="item">
              <CardContent>
                <S.TituloTopo>Datas</S.TituloTopo>
                <S.Column>
                  <S.Row className="agenda">
                    <FormControl className="data">
                      <KeyboardDateTimePicker
                        inputVariant="outlined"
                        ampm={false}
                        label="Início previsto"
                        placeholder="Início previsto"
                        value={inicio_previsto}
                        onChange={(event) => onInicioPrevisto(event)}
                        disablePast
                        error={false}
                        helperText={null}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </FormControl>
                  </S.Row>
                  <S.Row className="agenda">
                    <FormControl className="data">
                      <KeyboardDateTimePicker
                        inputVariant="outlined"
                        ampm={false}
                        label="Conclusão prevista"
                        placeholder="Conclusão prevista"
                        value={conclusao_prevista}
                        onChange={(event) => onConclusaoPrevista(event)}
                        disablePast
                        error={false}
                        helperText={null}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </FormControl>
                  </S.Row>
                </S.Column>
              </CardContent>
            </Card>
          </div>

          {/* <div className="card-form" style={{marginTop:'20px'}}>
                    <FormControl>

                        <FormControlLabel
                        control={
                            
                            <Switch
                                checked={email_automatico}
                                onChange={handleEmailAutomatico}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                            label="E-mail automático?"
                        />
                    </FormControl>
                </div> */}

          {/* {email_automatico === true ?
                    <div className="card-form recorrencia">
                        <Card className="item">
                            <>
                            <CardContent className="container_frequencia">
                                <S.Column>
                                             
                                    <FormControl>
                                        <TextField
                                        variant="outlined"
                                        label={'Destinatários'}
                                        value={destinatario}
                                        placeholder="Destinatários"
                                        onChange={event => setDestinatario(event.target.value)}
                                        />
                                    </FormControl> 
                                    <FormControl>
                                        <TextField
                                        variant="outlined"
                                        label={'Assunto'}
                                        value={assunto}
                                        placeholder=""
                                        onChange={event => setAssunto(event.target.value)}
                                        />
                                    </FormControl> 
                                    <FormControl>
                                        <TextField
                                        variant="outlined"
                                        label={'Corpo Email'}
                                        value={corpo_email}
                                        placeholder=""
                                        onChange={event => setCorpoEmail(event.target.value)}
                                        />
                                    </FormControl> 
                                </S.Column>
                            </CardContent>
                            </>
                        </Card>
                    </div> 
                : null } */}
        </S.ContainerCardForm>
        <button type="submit">Cadastrar</button>
      </S.Container>
    </>
  );
}
