import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .MuiFormControl-root {
    margin-bottom: 20px;
  }
  .data {
    flex-grow: 1;
    input {
      background-color: transparent;
      border: none;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
      width: 100%;
      height: 4rem;
      border-radius: 0.25rem;
      border: 1px solid #d7d7d7;
      background: #e7e9ee;

      font-weight: 400;
      font-size: 1rem;
      &::placeholder {
        color: var(--text-body);
      }
      //todo input que tiver um input acima vai receber margin top
      & + input {
        margin-top: 0.25rem;
      }
    }
  }
  .hora {
    width: 100px;
    max-width: 100px;
    margin-left: 20px;
    margin-top: -10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;
  .MuiFormControl-root {
    margin-bottom: 8px;
    margin-top: 10px;
  }
  .dia_semana {
    margin-top: 20px;
  }
  .cabecalho {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
    border-radius: 3px;
    justify-content: space-between;
    .cabecalhoLeft {
      .titulo {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        font-size: 1.1em;
        span {
          color: #333;
          font-weight: bold;
        }
        p {
          color: #666;
        }
      }
      .nota {
        width: auto !important;
        position: relative;
        margin-top: 10px;
        font-size: 1.1em;
        span {
          color: #333;
          font-weight: bold;
        }
        p {
          width: auto;
          display: inline-block;
          border-style: solid;
          border-width: 1px;
          color: #fff;
          padding: 5px 10px;
          border-radius: 3px;
          margin-left: 10px;
        }
      }
    }
    .cabecalhoRight {
      header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        right: 30px;
        figure {
          margin-bottom: 20px;
        }
      }
      .cabecalhoRespostas {
        justify-content: flex-end;
        width: 250px;
        table {
          min-width: 250px;
          border-collapse: collapse;
          border: 1px solid;
        }
        td.title {
          font-weight: bold;
        }
        td {
          padding: 5px;
          border: 1px solid;
        }
        td.cor {
          width: 50px;
        }
      }
    }
  }
`;

export const Container = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0px;

  label {
    color: var(--info-color);
  }

  .container_frequencia .botao {
    width: 100%;
    display: flex;
    align-itens: center;
    justify-content: center;
  }

  input,
  select {
    width: 100%;
    padding: 0 1rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }
  .MuiSelect-selectMenu {
    border: 1px solid #d7d7d7;
    background: #e7e9ee;
    &::placeholder {
      color: var(--text-body);
    }
  }

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.5rem;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }

  button[type='submit'] {
    width: 100%;
    max-width: 280px;
    padding: 0 1.5rem;
    height: 4rem;
    background: #33cc95;
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.8rem;
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
`;
export const ContainerImage = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 20px;

  .drop-container {
    margin-bottom: 20px;
  }
  p {
    font-size: 1.4em;
    line-height: 1.5em;
  }
  .MuiTypography-body1 {
    font-size: 1em;
  }
  .MuiGrid-spacing-xs-8 {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imagem_enviada {
    margin-left: 0px;
    p {
      width: 100%;
      font-size: 0.9em;
      word-break: break-all;
      padding: 20px;
    }
  }
`;

export const ContainerSenha = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  margin-bottom: 50px;

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.5rem;
    margin-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 20px;
    padding-top: 20px;
  }

  label {
    color: #ffffff;
  }

  input[type='password'],
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  button[type='submit'] {
    width: 100%;
    max-width: 220px;
    padding: 0 1.5rem;
    height: 3rem;
    background: var(--green);
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.8rem;
    font-weight: 600;
    margin-left: 18px;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
`;

export const TarefaLabel = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Codigo = styled.h1`
  border: 1px solid #f55;
  border-style: dashed;
  padding: 10px;
  font-weight: bold;
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  h1.codigo {
  }

  .card-form {
    width: 100%;
    max-width: 1000px;
    display: flex;
    gap: 1rem;
    margin: 0px 1rem;
    .item {
      flex: 1;
      width: calc((100% / 2) - 20px);
      margin-top: 15px;
    }
    &.recorrencia {
      margin-top: 20px;
    }

    @media (max-width: 900px) {
      width: 100%;
      margin: 0px;
      flex-direction: column;
      h3 {
        margin-top: 40px;
      }
      .item {
        flex: 1;
        width: 100%;
      }
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }
`;
export const ContainerResposta = styled.div`
  width: 100%;
  max-width: 1000px;

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.5rem;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }

  a.imprimir {
    background-color: var(--text-title);
    color: #fff;
    font-size: 1.5em;
    line-height: 1.5em;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  #listaResposta {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    .MuiAccordion-root {
      width: 100%;
    }
  }
`;

export const ContainerRespostaImagem = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Respostas = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  span {
    font-weight: bold;
  }
  p {
    color: #666;
    margin-top: 5px;
  }
  p span {
    color: #666;
  }
`;

export const TituloGrande = styled.h1`
  display: flex;
  width: 100%;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  span {
    font-weight: bold;
  }
  p {
    color: #666;
  }
  &.grupo {
    font-size: 1.8em;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--info-color);
    margin-bottom: 20px;
  }
`;

export const ButtonImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px #ccc;

  img {
    object-fit: cover;
  }
`;

export const TituloTopo = styled.h1`
  width: 100%;
  color: var(--info-color);
  font-size: 1.2em;
  margin-bottom: 20px;
  border-bottom: 1px solid #d7d7d7;
  padding-left: 5px;
`;
export const ButtonDeletar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  text-align: center;
  color: #fff;
  background-color: var(--red);
  border-radius: 0.25rem;
  border: none;
  margin-bottom: 1.25rem;
  margin: 1.25rem 10px;
  padding: 20px;
  transition: filter 0.2s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
  }
  span {
    font-weight: bold;
  }
`;

export const ButtonAdicionar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  height: 40px;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  text-align: center;
  color: #fff;
  background-color: var(--text-title);
  border-radius: 0.25rem;
  border: none;
  margin-bottom: 1.25rem;
  margin: 1.25rem 10px;
  padding: 20px;
  transition: filter 0.2s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
  }
  span {
    font-weight: bold;
  }
`;

export const Historico = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  justify-content: center;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  padding: 20px;
  .card_historico {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  p {
    color: #666;
    margin-top: 5px;
  }
  .zero {
    p {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  button {
    height: 3rem;
    padding: 0 2rem;
    font-size: 1rem;
    color: #fff;
    background-color: var(--info-color);
    border-color: var(--info-color);
    border-radius: 0.25rem;
    border: none;
    margin-bottom: 1.25rem;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }
    &:focus {
      outline: none;
    }
  }
`;

export const Tag = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  border-radius: 3px;
  .titulo {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    cursor: pointer;
    border-radius: 3px;
    h1 {
      color: #666;
      font-size: 1em;
      line-height: 1.2em;
    }
  }
  .container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #666;
    background-color: #fff;
    border-left-bottom-radius: 3px;
    border-right-bottom-radius: 3px;
    .tag {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 20px;
    }
  }
  p {
    color: #666;
    margin-top: 5px;
  }
  .zero {
    p {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
`;

export const FormQrCode = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  border-radius: 3px;
`;

export const ButttonHistorico = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  margin-right: 5px;
  font-size: 1em;
  line-height: 1.5em;
  text-align: center;
  color: #fff;
  background-color: purple;
  border-radius: 0.25rem;
  border: none;
  margin-bottom: 1.25rem;
  transition: filter 0.2s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
  }
`;

export const Comentario = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  border-radius: 3px;
  .conteudo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .nome {
    display: flex;
    width: 100%;
    align-items: center;
    color: #333;
    font-size: 1.1em;
    line-height: 1.2em;
    .MuiAvatar-root {
      margin-right: 10px;
    }
    span {
      margin-left: 5px;
      font-size: 0.7em;
    }
  }
  .historico {
    display: flex;
    width: 100%;
    color: #666;
    font-size: 0.9em;
    line-height: 1.2em;
    margin-left: 50px;
  }

  button {
    width: 100%;
    max-width: 180px;
    padding: 0 1.5rem;
    height: 2rem;
    background: #33cc95;
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.8rem;
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 700px;
  }
`;
